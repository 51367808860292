import React, { useState, FC, createContext } from "react";

export interface ProviderContext {
  setNewPDPLayout: (newPDPLayout: boolean) => void;
  newPDPLayout: boolean;
}
export const ProductConfiguratorContext = createContext<ProviderContext>({
  setNewPDPLayout: () => {},
  newPDPLayout: false
});

const ProductConfiguratorProvider: FC = ({ children }) => {
  const [newPDPLayout, setNewPDPLayout] = useState<boolean>(false);

  return (
    <ProductConfiguratorContext.Provider
      value={{
        newPDPLayout,
        setNewPDPLayout
      }}
    >
      {children}
    </ProductConfiguratorContext.Provider>
  );
};

export default ProductConfiguratorProvider;

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import {
  useUserSessionLazyQuery,
  useGetCartProjectLazyQuery
} from "@RHCommerceDev/hooks/queries";
import { updateUserForSessionMutation } from "@RHCommerceDev/graphql-client/queries/session";
import useErrorDialog from "@RHCommerceDev/hooks/useErrorDialog";
import { useMutation } from "@apollo/client";
import { useKeycloak } from "@RHCommerceDev/utils/Keycloak/KeyCloak";
import { useUserSessionSetAtom } from "@RHCommerceDev/hooks/atoms";
import yn from "yn";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import isEmpty from "lodash.isempty";
import useCheckEmailPopUp from "@RHCommerceDev/hooks/useCheckEmailPopUp/useCheckEmailPopUp";
import { COOKIE_WITH_SESSION } from "@RHCommerceDev/utils/constants";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import { isValidPostalCode as getIsValidPostalCode } from "@RHCommerceDev/utils/postalcode-validation";
import { countries } from "resources/countries-config.json";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import { CountryCode } from "@RHCommerceDev/hooks/useCountry";
import analyticsLoader from "analytics/loader";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import useLocale from "@RHCommerceDev/hooks/useLocale/useLocale";
import { useRhUserAtomValue } from "@RHCommerceDev/hooks/atoms";
import { useCookiesWithPermission } from "@RHCommerceDev/hooks/useCookiesWithPermission";
import { sleep } from "@RHCommerceDev/utils/sleep";

const SessionProviderV2 = ({ children }) => {
  const { showError } = useErrorDialog();
  const {
    FEATURE_PDP_CART_BROKER,
    FEATURE_FASTER_CHECKOUT_SIGN_IN,
    PUBLIC_URL,
    FEATURE_PERFORMANCE_SESSION_MEMBERSHIP
  } = useEnv();
  const { keycloak } = useKeycloak();
  const setUserSession = useUserSessionSetAtom();
  const rhUser = useRhUserAtomValue();
  const language = useLocale();
  const [called, setCalled] = useState(false);
  const [isEventTrigger, setIsEventTrigger] = useState(
    !processEnvServer ? !!localStorage.getItem("isLoggedin") : undefined
  );
  const { setStorageValueWrapper } = useCookiesWithPermission();

  const isGuesthouse = PUBLIC_URL?.match(
    /^(?:http(?:s)?:\/\/)?(?:[^\.]+\.)?rhguesthouse/
  );
  const { setSessionCookie, emailPopupData } = useCheckEmailPopUp();

  const isCheckout = useMemo(() => {
    try {
      return window?.location?.pathname?.includes("/checkout");
    } catch (error) {
      return false;
    }
  }, []);

  const UNKNOWNpreventer = useCallback((text = "") => {
    try {
      return text?.includes("UNKNOWN") || text?.includes("undefined")
        ? undefined
        : text;
    } catch (error) {
      return undefined;
    }
  }, []);

  const country = getCountryFromUrl();
  const pc = useIsoCookies(["pc"], true)?.pc;
  const cookieCountry = useIsoCookies(["country_cookie"], true)?.country_cookie;
  const { handleSaveCookies } = useUserPreferences();

  const isValidPostalCode = useMemo(
    () => getIsValidPostalCode(pc, country as CountryCode),
    [country, pc]
  );

  const postalCode = useMemo(
    () =>
      isValidPostalCode && cookieCountry === country
        ? pc
        : countries[country]?.defaultValues?.postalCode,
    [country, isValidPostalCode, pc, cookieCountry]
  );

  const runOnce = useRef(false);
  const handleSaveCookiesRunOnce = useCallback(
    (userSession: SessionUserType) => {
      if (runOnce?.current) {
        return;
      }
      // NO SPECIFIC REGEX FOR DE,BE,ES,FR AND US, ALL CAN HAVE 5 DIGITS
      // if (!isValidPostalCode) {
      handleSaveCookies(
        { country, postalCode: postalCode, language },
        userSession,
        true,
        false
      );
      // }
      runOnce.current = true;
    },
    [country, handleSaveCookies, isValidPostalCode, pc]
  );

  const isCheckoutAndFeatureFasterCheckoutSignIn =
    yn(FEATURE_FASTER_CHECKOUT_SIGN_IN) && isCheckout;

  const [updateUserForSessionQuery] = useMutation<Mutation>(
    updateUserForSessionMutation,
    {
      context: {
        fetchOptions: {
          method: "POST"
        }
      },
      onCompleted: ({ updateUserForSession }) => {
        setUserSession(prev => ({
          ...prev,
          ...updateUserForSession,
          loading: false
        }));
        if (
          isEmpty(emailPopupData) ||
          emailPopupData?.sessionId !== updateUserForSession?.sessionId
        ) {
          setSessionCookie?.(COOKIE_WITH_SESSION, {
            sessionId: updateUserForSession?.sessionId,
            isPopClosed: false,
            clickCount: 1
          });
        }
      },
      onError: showError
    }
  );

  const [getCartProjection] = useGetCartProjectLazyQuery();
  const [getUserSession] = useUserSessionLazyQuery({
    async onCompleted({ getUserForSession }) {
      setUserSession(prev => ({
        ...prev,
        isMyAccountIconDisabled: false
      }));
      if (
        isEmpty(emailPopupData) ||
        emailPopupData?.sessionId !== getUserForSession?.sessionId
      ) {
        setSessionCookie?.(COOKIE_WITH_SESSION, {
          sessionId: getUserForSession?.sessionId,
          isPopClosed: false,
          clickCount: 1
        });
      }

      const userTypes = ["REGISTERED", "TRADE", "CONTRACT"];
      if (
        !isGuesthouse &&
        !isCheckoutAndFeatureFasterCheckoutSignIn &&
        !isEmpty(keycloak?.tokenParsed) &&
        getUserForSession?.email !== keycloak?.tokenParsed?.email &&
        userTypes.includes(getUserForSession?.rhUser?.userType) &&
        !called
      ) {
        let anonymousCartId = "";
        setUserSession(prev => {
          anonymousCartId = prev?.anonCartId || "";
          return {
            ...prev,
            ...getUserForSession,
            loading: true,
            loadingUpdateUserSession: true
          };
        });
        const { data } = await updateUserForSessionQuery({
          variables: {
            currentCartId:
              anonymousCartId || getUserForSession?.currentCartId || "",
            useCartBroker: yn(FEATURE_PDP_CART_BROKER),
            userInput: {
              firstName: UNKNOWNpreventer(keycloak?.tokenParsed?.given_name),
              lastName: UNKNOWNpreventer(keycloak?.tokenParsed?.family_name),
              email: keycloak?.tokenParsed?.email,
              platform: "NEW",
              userType: getUserForSession?.rhUser?.userType,
              newUser: false
            },
            skipMembershipInfo: yn(FEATURE_PERFORMANCE_SESSION_MEMBERSHIP)
          }
        });
        getUserForSession = data?.updateUserForSession || getUserForSession;
        if (keycloak?.tokenParsed?.email) {
          setCalled(true);
        }
      }
      if (getUserForSession?.currentCartId) {
        getCartProjection({
          id: getUserForSession?.rhUser?.id,
          email: getUserForSession?.rhUser?.email
        });
      }
      setUserSession(prev => {
        const previousCall = Math.max(0, Number(prev?.prevLoadingCall) || 0);

        return {
          ...prev,
          ...getUserForSession,
          loading: previousCall === 0 ? false : true,
          loadingUpdateUserSession: false
        };
      });
      handleSaveCookiesRunOnce(getUserForSession);
    }
  });

  const handleCallGetUserSession = () => {
    setUserSession(prev => ({
      ...prev,
      isMyAccountIconDisabled: true
    }));
    getUserSession();
  };

  const onClick = useCallback(() => {
    getUserSession();
  }, [getUserSession]);

  useEffect(() => {
    /* use effect and check if there is any wistia hero on the page to delay the getuser and getCartProhjection until the video has been loaded */
    if (window._rh_wait_for_wistia_hero) {
      window._rh_on_wistia_hero_play = window._rh_on_wistia_hero_play || [];
      window._rh_on_wistia_hero_play.push(handleCallGetUserSession);
    } else {
      handleCallGetUserSession();
    }
  }, [keycloak?.tokenParsed]);

  useEffect(() => {
    async function triggerEvent() {
      await sleep(500);
      if (
        !processEnvServer &&
        keycloak.authenticated &&
        !isEventTrigger &&
        !rhUser?.loading &&
        (rhUser?.userType === "REGISTERED" ||
          rhUser?.userType === "TRADE" ||
          rhUser?.userType === "CONTRACT")
      ) {
        if (rhUser?.newUser) {
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.REGISTER.INT_TYPE,
              {
                userId: rhUser?.id,
                loggedin: "Success"
              }
            )
          );
        } else {
          analyticsLoader(a =>
            a.emitAnalyticsEvent(
              document.querySelector("#spa-root > *")! as HTMLElement,
              a.EVENTS.LOGIN.INT_TYPE,
              {
                userId: rhUser?.id,
                loggedin: "Success"
              }
            )
          );
        }
        setIsEventTrigger(true);
        setStorageValueWrapper({
          storageKey: "isLoggedin",
          value: true
        });
      }
    }
    triggerEvent();
  }, [keycloak?.authenticated, rhUser?.userType, isEventTrigger]);

  return (
    <React.Fragment>
      <button style={{ fontSize: 50, display: "none" }} onClick={onClick}>
        get user again
      </button>
      {children}
    </React.Fragment>
  );
};

export default memoize(SessionProviderV2);

import React, { useState, FC, createContext } from "react";

export interface ProviderContext {
  setCountry: (countries: any) => void;
  country: any;
}
export const AvailableCountryContext = createContext<ProviderContext>({
  setCountry: () => {},
  country: []
});

const AvailableCountryProvider: FC = ({ children }) => {
  const [country, setCountry] = useState<string[] | undefined>();

  return (
    <AvailableCountryContext.Provider
      value={{
        country,
        setCountry
      }}
    >
      {children}
    </AvailableCountryContext.Provider>
  );
};

export default AvailableCountryProvider;

const membership = {
  id: "/my-account/membership.jsp",
  targetUrl: "/my-account/membership.jsp",
  displayName: "Membership",
  labelCode: "membership"
};

const paymentMethods = {
  id: "/my-account/payment-info.jsp",
  targetUrl: "/my-account/payment-info.jsp",
  displayName: "Payment Methods",
  labelCode: "paymentMethods"
};

const orderHistory = {
  id: "/my-account/order-history.jsp",
  targetUrl: "/my-account/order-history.jsp",
  displayName: "Order History",
  labelCode: "orderHistory"
};

const wishlist = {
  id: "/my-account/wish-list.jsp",
  targetUrl: "/my-account/wish-list.jsp",
  displayName: "WishList",
  labelCode: "wishlist"
};

const addressBook = {
  id: "/my-account/address-book.jsp",
  targetUrl: "/my-account/address-book.jsp",
  displayName: "Address Book",
  labelCode: "addressBook"
};

const profile = {
  id: "/my-account/profile.jsp",
  targetUrl: "/my-account/profile.jsp",
  displayName: "Profile",
  labelCode: "profile"
};

const giftRegistry = {
  id: `/gift-registry`,
  targetUrl: `/gift-registry`,
  displayName: "Gift Registry",
  labelCode: "giftRegistry"
};

const invoiceHistory = {
  id: `/my-account/invoice-history.jsp`,
  targetUrl: `/my-account/invoice-history.jsp`,
  displayName: "Invoice History",
  labelCode: "invoiceHistory"
};

const signOut = {
  id: "/signout",
  targetUrl: "/signout",
  displayName: "Sign Out",
  labelCode: "signOut"
};

export const drawerAccountNavigation = [
  membership,
  paymentMethods,
  orderHistory,
  wishlist,
  addressBook,
  profile,
  giftRegistry
];

export const inlineAccountNavigation = [
  orderHistory,
  wishlist,
  membership,
  giftRegistry,
  invoiceHistory,
  profile,
  signOut
];

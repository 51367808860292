import AEMWrapper from "aem/ComponentWrapper";
import React, { FC } from "react";
import { AEMComponentProps } from "aem/types";
// import loadable from "@loadable/component";
// const Footer = loadable(
//   () => import(/* webpackChunkName: "container-footer" */ "."),
//   {
//     fallback: <></>
//   }
// );
import RHRFooter from "containers/RHRFooter";

export type FooterProps = AEMComponentProps & {
  data?: ContentFragmentResponse;
  loading?: Boolean;
  customBackgroundTheme?: "White";
  bgColor?: string;
  styleOverrides?: any;
  isModalTrigger?: boolean;
  isInEditor?: boolean;
  isVisible?: boolean;
  conciergeHide?: boolean | "true" | "false";
};
const Component: FC<FooterProps> = ({
  customBackgroundTheme = "White",
  bgColor,
  isModalTrigger,
  isInEditor,
  isVisible,
  conciergeHide
}) => {
  return (
    <RHRFooter
      customBackgroundTheme={customBackgroundTheme}
      bgColor={bgColor}
      isModalTrigger={isModalTrigger}
      isInEditor={isInEditor}
      isVisible={isVisible}
      conciergeHide={conciergeHide}
    />
  );
};

export default AEMWrapper(
  {
    type: "rh/components/content/footer",
    config: {
      emptyLabel: "Footer",
      isEmpty: _ => false
    }
  },
  Component
);

export const formatDisplayName = (
  displayName: string,
  searchText: string = "",
  parentCategoryName?: string | null,
  fontColor: string = "#000000"
) => {
  displayName = decodeURIComponent(displayName);
  const matches = displayName.match(new RegExp(searchText, "i"));
  if (!!matches) {
    matches.forEach(match => {
      displayName = displayName.replace(
        match,
        `<strong style="color:${fontColor}">${match}</strong>`
      );
    });
  }

  if (!!parentCategoryName) {
    return `${displayName} in ${parentCategoryName}`;
  }

  return displayName;
};

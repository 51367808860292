import React, { createContext, useState, useCallback } from "react";
import { ApolloError } from "@apollo/client";
import ErrorDialog from "dialog-error";

const DEFAULT_ERROR = "Something went wrong";

type ErrorContextType = {
  showError: (
    error: ErrorType,
    customHandler?: (error: string) => string
  ) => void;
};

export type ErrorType = ApolloError | undefined | string;

export const ErrorContext = createContext<ErrorContextType>({
  showError: () => {}
});

const parseError = (error: ApolloError) => {
  try {
    const errorString = JSON.stringify(error.networkError || {}, null, 2);
    const messageString: string =
      JSON.parse(errorString).result.errors[0].message;
    const parsedError = JSON.parse(
      messageString.substring(messageString.indexOf("{"))
    );

    const message = (): string | undefined => {
      if (parsedError.errors) {
        return (
          parsedError.errors[0]?.message ||
          parsedError.errors[0]?.detailedMessage
        );
      } else if (parsedError.errorMessages) {
        return Object.values(parsedError.errorMessages)[0] as string;
      } else if (parsedError.message) {
        return parsedError.message;
      }

      return undefined;
    };

    return { message: message(), rawMessage: messageString };
  } catch (err) {
    return { message: DEFAULT_ERROR, rawMessage: DEFAULT_ERROR };
  }
};

export const getError = (
  error: ErrorType,
  customHandler?: (error: string | undefined) => string
) => {
  if (typeof error === "undefined") {
    return DEFAULT_ERROR;
  }

  if (typeof error === "string") {
    return error;
  }

  const { message, rawMessage } = parseError(error);

  if (!!customHandler) {
    const customMessage = customHandler(rawMessage || message);
    return !!customMessage ? customMessage : message;
  }
  return message || DEFAULT_ERROR;
};

const ErrorProvider: React.FC = ({ children }) => {
  const [error, setError] = useState<string | undefined>();

  const showError = useCallback(
    (err: ErrorType, customHandler) => setError(getError(err, customHandler)),
    []
  );

  return (
    <ErrorContext.Provider value={{ showError }}>
      <>
        {error && (
          <ErrorDialog
            onClose={() => setError(undefined)}
            open={!!error}
            customTitle={error}
            disableButton
          />
        )}
        {children}
      </>
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;

import { createStyles, makeStyles, Theme } from "utils/material-ui-core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hoverSearch: {
      zIndex: 10,
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "1px solid #999999"
      }
    },
    overlaySearch: {
      position: "absolute",
      backgroundColor: "white",
      zIndex: 10,
      width: "100%",
      "&::before": {
        position: "absolute",
        left: "-100%",
        content: "' '",
        height: "-webkit-fill-available",
        backgroundColor: "white",
        right: 0
      }
    },
    drawerRoot: {
      "& .MuiBackdrop-root": {
        background: "rgba(0, 0, 0, 0.2)"
      }
    },
    drawer: {
      overflow: "visible",

      "& .MuiDialogContent-root": {
        [theme.breakpoints.up("sm")]: {
          padding: 88
        },
        [theme.breakpoints.up("xl")]: {
          padding: 128
        }
      },
      [theme.breakpoints.up("xs")]: {
        width: "75vw"
      },
      [theme.breakpoints.up("sm")]: {
        width: "66.66vw"
      },
      [theme.breakpoints.up("md")]: {
        width: "50vw"
      }
    },
    searchBox: {
      width: "196px",
      height: "44px",
      flexShrink: 0,
      "& .MuiInput-underline:before": {
        borderBottom: "0.75px solid #898886"
      }
    }
  })
);

import React, { FC, useCallback, useRef, useState } from "react";
import memoize from "utils/memoize";
import RHMenuDrawer, { RHMenuDrawerProps } from "component-rh-menu-drawer";
import RHShippingSelections from "component-rh-shipping-selections";
import RHRFlagIcon from "icon-flag-rhr";
import useUserPreferences from "hooks/useUserPreferences";
import { useStyles } from "container-rhr-header/CountryNavMenu/style";
import { useIsoCookies } from "hooks/useIsoCookies";
import { COUNTRY_COOKIE } from "utils/constants";
import getCountryFromUrl from "@RHCommerceDev/utils/getCountryFromUrl";

export interface ExtendedCountryNavMenuProps extends RHMenuDrawerProps {
  style?: any;
  onClose?: () => void;
  onOpen?: () => void;
  disabled?: boolean;
}

export const CountryNavMenu: FC<ExtendedCountryNavMenuProps> = ({
  disabled,
  style,
  onClose,
  onOpen,
  ...props
}) => {
  const { country } = useUserPreferences();
  const isoCookies = useIsoCookies([COUNTRY_COOKIE]);
  const currentCountry =
    isoCookies[COUNTRY_COOKIE] || getCountryFromUrl() || "US";
  /* state */
  const [open, setOpen] = useState(false);

  /* handle click on flag */
  const handleFlagClick = useCallback(() => {
    setOpen(true && !disabled);
    onOpen?.();
  }, [disabled, onOpen, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [setOpen, onClose]);

  const classes = useStyles();
  const anchorEl = useRef(null);

  return (
    <>
      <div
        className="flex items-center cursor-pointer"
        onClick={handleFlagClick}
        ref={anchorEl}
      >
        <RHRFlagIcon country={currentCountry} />
      </div>
      <RHMenuDrawer
        {...props}
        open={open}
        anchor="right"
        PaperProps={{
          className: `overflow-visible w-[75vw] xs:w-[50vw] sm:w-[330px] md:w-[400px] lg:w-[533px] ${classes.drawer}`
        }}
        titlePaddingClassNames={classes.titlePadding}
        closeIconClassnames={classes.closeIcon}
        closeButtonRootClassnames="p-[2px]"
        onClose={handleClose}
        contentStyle={{ overflowY: "scroll", ...style?.RHMenuDrawer }}
      >
        <RHShippingSelections setIsOpen={() => setOpen?.(false)} popperType />
      </RHMenuDrawer>
    </>
  );
};

export default memoize(CountryNavMenu);

import React, { FC, useMemo, useContext } from "react";
import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import yn from "yn";

import { createMuiTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import {
  BREAKPOINT_XL,
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XS,
  PAGE_BG_GREY
} from "@RHCommerceDev/utils/constants";
import { useIsoCookies } from "@RHCommerceDev/hooks/useIsoCookies";
import memoize from "@RHCommerceDev/utils/memoize";
import { useEnv } from "@RHCommerceDev/hooks/useEnv";
import { useLocation } from "react-router-dom";
import useUserPreferences from "@RHCommerceDev/hooks/useUserPreferences";
import useTypographyStyles from "@RHCommerceDev/hooks/useTypographyStyles";
import classNames from "classnames";
import { bgGreyHiddenRegex } from "@RHCommerceDev/utils/regex";
import UserPreferences from "./UserPreferences";
import UserPreferencesV2 from "./UserPreferencesV2";
import AEMFotterContent from "./AEMFooterContent";
import WORHFooterContent from "./RHRFooterContent";
import useSite from "@RHCommerceDev/hooks/useSite";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { UseRequestProvider } from "@RHCommerceDev/custom-providers/Provider";
import { useAppId } from "@RHCommerceDev/hooks-use-app-id";

export interface RHRFooterProps {
  styleOverrides?: any;
  customBackgroundTheme?: "White";
  bgColor?: string;
  brand?: string;
  isModalTrigger?: boolean;
  isInEditor?: boolean;
  isVisible?: boolean;
  conciergeHide?: boolean | "true" | "false";
}

const rhTextTheme = createMuiTheme({
  breakpoints: createBreakpoints({
    values: {
      xs: BREAKPOINT_XS,
      sm: BREAKPOINT_SM,
      md: BREAKPOINT_MD,
      lg: BREAKPOINT_LG,
      xl: BREAKPOINT_XL
    },
    get up() {
      return (key: number | string) => {
        const values = this.values as any;
        return `@media (min-width: ${values[key]}px)`;
      };
    },
    get down() {
      return (key: number | string) => {
        const values = this.values as any;
        return `@media (max-width: ${values[key] - 0.05}px)`;
      };
    }
  })
});

const useStyles = (props: any) => {
  const { styleOverrides, isModalTrigger } = props;
  const { customBackgroundTheme } = props;
  return makeStyles((theme: Theme) =>
    createStyles({
      countryPreferencesWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      countryPreferences: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 40,
        "&& .RHFooterSelect, .MuiInputBase-root, .MuiSelect-select:focus": {
          background: "transparent"
        }
      },
      separator: {
        paddingBottom: 120,
        background: "transparent"
      },
      marketingSeparator: {
        paddingBottom: 80,
        [rhTextTheme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      shippingSeparator: {
        background: "transparent",
        margin: "20px 40px",
        [rhTextTheme.breakpoints.down("md")]: {
          margin: "20px 30px"
        },
        [rhTextTheme.breakpoints.down("lg")]: {
          margin: "20px 40px"
        },
        [rhTextTheme.breakpoints.down(375)]: {
          margin: "10px auto"
        }
      },
      smCountryPreferences: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        paddingTop: "24px"
      },
      copyright: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "51.2px",
        background: "transparent",
        color: "#999999 !important"
      },
      select: {
        color: "#404040",
        fontSize: "11px"
      },
      smSelect: {
        color: "#404040",
        fontSize: "11px",
        fontWeight: 500
      },
      selectIcon: {
        color: "#000",
        right: "1px",
        fontSize: "11px"
      },
      smSelectIcon: {
        color: "#000",
        right: "20px",
        fontSize: "11px"
      },
      option: {
        minWidth: 140,
        margin: "10px 10px 10px 13px",
        color: "#808080",
        cursor: "pointer",
        fontSize: "11px",
        paddingBottom: "10px",
        borderBottom: "1px solid #ddd",
        "&:last-child": {
          borderBottom: "none"
        }
      },
      headerOptionWrapper: {
        pointerEvents: "none",
        display: "flex",
        fontSize: "11px",
        alignItems: "center",
        paddingLeft: "32px",
        lineHeight: "20px"
      },
      headerOptionSelected: {
        color: "#808080",
        fontSize: "13px",
        marginLeft: "5px",
        textTransform: "uppercase",
        marginBottom: "8px"
      },
      headerOption: {
        color: "#404040",
        fontSize: "13px",
        marginBottom: "8px"
      },
      headerOptionDivider: {
        borderBottom: "1px solid #ddd",
        display: "flex",
        marginLeft: "32px",
        marginRight: "32px",
        marginBottom: "5px"
      },
      itemWrapper: {
        display: "flex",
        alignItems: "center"
      },
      imageStyle: {
        width: 16,
        marginRight: 8
      },
      measureUnits: {
        textTransform: "capitalize"
      },
      footerContainer: {
        width: "100%",
        clear: "both",
        backgroundColor: "transparent",
        [rhTextTheme.breakpoints.up("sm")]: {
          padding: "120px 32px"
        },
        [rhTextTheme.breakpoints.down("sm")]: {
          padding: "120px 32px",
          display: "none"
        },
        [rhTextTheme.breakpoints.up("md")]: {
          padding: "120px 40px",
          ...(styleOverrides || {})
        },
        [rhTextTheme.breakpoints.up("lg")]: {
          padding: "120px 40px",
          ...(styleOverrides || {})
        },
        [rhTextTheme.breakpoints.up("xl")]: {
          padding: "120px 80px",
          maxWidth: 1920,
          margin: "0px auto",
          ...(styleOverrides || {})
        },
        "@media print": {
          display: "none"
        }
      },
      footerContainerwithEmail: {
        [rhTextTheme.breakpoints.down("sm")]: {
          padding: "0px",
          display: "block"
        }
      },
      footerHeader: {
        color:
          customBackgroundTheme === "theme.palette.common.black"
            ? "theme.palette.common.white"
            : "theme.palette.common.black"
      },
      footerMenus: {
        overflow: "hidden",
        margin: "0 auto",
        boxSizing: "border-box",
        textAlign: "left",
        letterSpacing: "0.15em"
      },
      footerMenusWithemail: {
        [rhTextTheme.breakpoints.down("sm")]: {
          display: "none"
        }
      },
      footerItems: {
        color: "#666666",
        pointerEvents: isModalTrigger ? "none" : undefined,
        textTransform: "uppercase"
      },
      grid: {
        display: "flex",
        flexWrap: "nowrap",
        gap: 40,
        listStyle: "none",
        margin: "0",
        padding: "0",
        letterSpacing: "-0.31em"
      },

      gridItemInner: {
        "&& h3": {
          font: '12px/27px "minion-pro", Times, "Times New Roman"',
          textTransform: "uppercase",
          marginBottom: "14px",
          color: customBackgroundTheme === "Black" ? "#fff" : "#666"
        },

        "&& ul": {
          listStyle: "none",
          padding: "0",
          marginTop: "14px",
          marginBottom: "14px"
        },

        "&& li": {
          marginBottom: "16px",
          textTransform: "uppercase"
        },

        "&& a": {
          textDecoration: "none",
          color: customBackgroundTheme === "Black" ? "#999" : "#666"
        },

        "&& a:hover": {
          textDecoration: "underline",
          color: customBackgroundTheme === "Black" ? "#fff" : "#666"
        },

        "&& a svg": {
          width: 10,
          height: 10,
          marginLeft: 5
        }
      }
    })
  );
};

export const RHRFooterContent: FC<RHRFooterProps> = ({
  styleOverrides,
  customBackgroundTheme,
  bgColor,
  brand,
  isModalTrigger,
  isInEditor,
  isVisible,
  conciergeHide
}) => {
  const { country, language, measurement, ...rest } = useUserPreferences();
  const environment = useEnv();
  const FEATURE_AEM_FOOTER = yn(environment.FEATURE_AEM_FOOTER);
  const FEATURE_EU_EXPANSION = yn(environment.FEATURE_EU_EXPANSION);
  const classes = useStyles({
    customBackgroundTheme,
    styleOverrides,
    isModalTrigger
  })();
  const typographyclasses = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseBody2", "rhBaseCaption"]
  });
  const { isConcierge } = useAppId();

  const date = new Date();
  const year = date.getFullYear();
  const location = useLocation();
  const req = processEnvServer ? useContext(UseRequestProvider) : {};
  const pathname = processEnvServer ? (req as any).path : location.pathname;

  const UserPreferencesFlagged = useMemo(
    () => (FEATURE_EU_EXPANSION ? UserPreferencesV2 : UserPreferences),
    [FEATURE_EU_EXPANSION]
  );
  const isJspPage = /\.jsp/.test(pathname);
  const isSearchPage = location.pathname.includes("/search/");
  const isMyAccountPage = /\my-account/.test(pathname);

  const footerContentVisibility =
    isVisible ||
    processEnvServer ||
    isJspPage ||
    isSearchPage ||
    isMyAccountPage
      ? "visible"
      : "hidden";

  const hideInConcierge = yn(conciergeHide) && isConcierge;

  return (
    <Container
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        visibility: footerContentVisibility,
        background:
          bgColor ||
          (!bgGreyHiddenRegex?.test(location?.pathname) ||
          /^\/checkout\/shopping_cart\.jsp/.test(location.pathname)
            ? PAGE_BG_GREY
            : "white")
      }}
    >
      {isModalTrigger && (
        <style>
          {`#country-selection, #language-selection, #country, #language {
            pointer-events: none;
          }`}
        </style>
      )}
      {!hideInConcierge && (
        <>
          {FEATURE_AEM_FOOTER ? (
            <AEMFotterContent
              classes={classes}
              brand={brand}
              isInEditor={isInEditor}
            />
          ) : (
            <>
              <WORHFooterContent classes={classes} brand={brand} />
              <UserPreferencesFlagged classes={classes} />
            </>
          )}
        </>
      )}
      <span
        className={classNames([
          classes.copyright,
          typographyclasses.rhBaseCaption
        ])}
      >
        © {year} RH
      </span>
    </Container>
  );
};

const RHRFC = memoize(RHRFooterContent);

RHRFooterContent.defaultProps = {};

const RHRFooter: FC<RHRFooterProps> = memoize((props: any) => {
  const { styleOverrides, conciergeHide } = props;
  const { customBackgroundTheme } = props;
  const { bgColor } = props;
  const { isModalTrigger } = props;
  const { isInEditor } = props;
  const { isVisible } = props;
  const brand = useSite();
  const deviceType =
    useIsoCookies(["PF_EXP"])?.PF_EXP?.toLowerCase() || "desktop";
  let contentKey = `footer-links-multisite-${deviceType}`;
  if (deviceType === "desktop") {
    if (brand === "BC") {
      contentKey = contentKey + "-bc";
    }
    if (brand === "TN") {
      contentKey = contentKey + "-tn";
    }
  }

  return (
    <RHRFC
      styleOverrides={styleOverrides}
      customBackgroundTheme={customBackgroundTheme}
      bgColor={bgColor}
      brand={brand}
      isModalTrigger={isModalTrigger}
      isInEditor={isInEditor}
      isVisible={isVisible}
      conciergeHide={conciergeHide}
    />
  );
});

export default memoize(RHRFooter);

import { CE_OPEN_DIALOG } from "./events";

if (window && "customElements" in window) {
  window.customElements.define(
    "dialog-anchor",
    class DialogAnchor extends HTMLElement {
      constructor() {
        super();
        this.onAnchorClicked = this.onAnchorClicked.bind(this);
      }
      connectedCallback() {
        this.addEventListener("click", this.onAnchorClicked);
      }
      disconnectedCallback() {
        this.removeEventListener("click", this.onAnchorClicked);
      }
      onAnchorClicked(event: Event) {
        event.stopPropagation();
        document.dispatchEvent(
          new CustomEvent(CE_OPEN_DIALOG, {
            detail: {
              type: this.getAttribute("type"),
              promoId: this.getAttribute("promo-id")
            }
          })
        );
      }
    }
  );
}

export default {};

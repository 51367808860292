import React, { FC } from "react";
import { makeStyles, createStyles } from "utils/material-ui-core";
import classNames from "classnames";

const useStyles = makeStyles(() => {
  return createStyles({
    bodyContainer: {
      "& .rhrCgGridContainer .cg-slide:first-child": {
        paddingTop: "0px"
      }
    }
  });
});

const Body: FC = props => {
  const classes = useStyles();

  return (
    <main
      id="main"
      className={classNames(classes.bodyContainer)}
      style={{
        flex: 1,
        position: "relative",
        zIndex: 1099
      }}
      {...props}
    />
  );
};

export default Body;
